import { computed, ref, Ref } from 'vue';
import { createI18n, LocaleMessageDictionary, VueMessageType } from 'vue-i18n';
import { LocaleItem } from '@/types';
import { getCookie, setCookie } from '@/util/cookies';

const LOCALE_COOKIE_NAME = 'dc_locale_pref';
const selectedLocaleId = ref(getCookie(LOCALE_COOKIE_NAME)) as Ref<string>;
const i18n = createI18n({});

const ICU_LOCALES = {
  de: 'de', en: 'en', enAU: 'en', enCA: 'en', it: 'it', fr: 'fr', se: 'sv', sl: 'sl',
};

const availableLocales: Record<string, LocaleItem> = {
  de: {
    id: 'de',
    title: 'Deutsch',
    order: 1,
  },
  en: {
    id: 'en',
    title: 'English',
    order: 2,
  },
  enAU: {
    id: 'enAU',
    title: 'English (Australian)',
    order: 3,
  },
  enCA: {
    id: 'enCA',
    title: 'English (Canadian)',
    order: 4,
  },
  fr: {
    id: 'fr',
    title: 'French',
    order: 5,
  },
  frCA: {
    id: 'frCA',
    title: 'French (Canadian)',
    order: 6,
  },
  it: {
    id: 'it',
    title: 'Italiano',
    order: 7,
  },
  se: {
    id: 'se',
    title: 'Svenska',
    order: 8,
  },
  sl: {
    id: 'sl',
    title: 'Slovenščina',
    order: 9,
  },
};

const supportedLocales: LocaleItem[] = [];

export default function useLocale() {
  const selectedLocale = computed(() => supportedLocales.find(
    (localeItem: LocaleItem) => localeItem.id === selectedLocaleId.value,
  ));

  const onUpdate = (id: string) => {
    if (!id) return;
    const newLocale = supportedLocales.find(
      (localeItem: LocaleItem) => localeItem.id === id,
    );
    i18n.global.locale = id;
    i18n.global.fallbackLocale = id;
    setCookie(LOCALE_COOKIE_NAME, newLocale?.id || '');
    selectedLocaleId.value = newLocale?.id || '';
  };

  const initLocales = async (
    locales: Array<string>,
    defaultLocale: string,
    messages: Record<string, LocaleMessageDictionary<VueMessageType>>,
  ) => {
    await locales.forEach((locale: string) => {
      supportedLocales.push(availableLocales[`${locale}`]);
      i18n.global.setLocaleMessage(locale, messages[`${locale}`]);
    });

    if (defaultLocale && '0' !== defaultLocale) {
      onUpdate(defaultLocale);
    } else {
      onUpdate('en');
    }
  };

  return {
    i18n,
    ICU_LOCALES,
    supportedLocales,
    selectedLocale,
    selectedLocaleId,
    onUpdate,
    initLocales,
  };
}
