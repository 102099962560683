import { ref } from 'vue';
import { BrandingTools, BrandingTranslationsMessages } from '@/types';
import { cloneJSON } from '@/util/JSON';
import useConfiguration from './generalConfigurations';
import buttons from './componentsStyling/buttons.json';
import header from './componentsStyling/header.json';
import footer from './componentsStyling/footer.json';
import bannerExplore from './contentManagement/bannerExplore.json';
import bannerMydocs from './contentManagement/bannerMydocs.json';
import searchSuggestions from './contentManagement/searchSuggestions.json';
import translations from './translations/translations.json';
import en from './translations/en.json';
import enAU from './translations/enAU.json';
import enCA from './translations/enCA.json';
import de from './translations/de.json';
import fr from './translations/fr.json';
import frCA from './translations/frCA.json';
import it from './translations/it.json';
import se from './translations/se.json';
import sl from './translations/sl.json';

const messages: BrandingTranslationsMessages = {
  de,
  en,
  enAU,
  enCA,
  fr,
  frCA,
  it,
  se,
  sl,
};

const defaults = ref(
  cloneJSON(
    {
      generalConfigurations: ({
        ...useConfiguration(),
      }),
      componentsStyling: ({
        buttons,
        header,
        footer,
      }),
      translations: ({
        ...translations,
        messages,
      }),
      contentManagement: ({
        bannerExplore,
        bannerMydocs,
        searchSuggestions,
      }),
    },
  ) as BrandingTools,
);

export default defaults;
